import React from 'react';

export const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="currentColor" transform="translate(-933 -458)">
          <g transform="translate(393 434)">
            <g transform="translate(536 20)">
              <path d="M18.77 4L20 5.23 13.23 12 20 18.77 18.77 20 12 13.23 5.23 20 4 18.77 10.769 12 4 5.23 5.23 4 12 10.769 18.77 4z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
